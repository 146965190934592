
.color {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	aspect-ratio: 1;
	user-select: none;
	cursor: pointer;
	background-color: var(--background-color);
	border: none;
	appearance: none;
}

.color:focus-visible {
	outline: none;
	box-shadow: inset 0 0 0 2px var(--focus-ring-color, black);
}

.color--active::after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(255, 255, 255, 0.05);
	user-select: none;
}

.color__label {
	display: none;
	font-size: calc(7vw / var(--columns, 6));
	font-family: var(--default-font-family);
	font-weight: 400;
	letter-spacing: 0.045em;
	color: var(--text-color, black);
	opacity: 1;
}

@media (prefers-contrast: more) {
	.color__label {
		font-weight: 500;
	}
}

@media (hover: hover) and (min-width: 650px) {
	.color.color--with-label:hover > .color__label {
		display: block;
	}

	.color.color--with-label:focus-visible > .color__label {
		display: block;
	}
}
