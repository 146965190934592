.app {
	display: grid;
	grid-template-rows: 1fr max-content;
	grid-template-columns: 100%;
	width: 100%;
	min-height: 100vh;
}

.app__header {
	position: sticky;
	bottom: 0;
	grid-row: 2;
	grid-column: 1;
	pointer-events: none;
	z-index: 2;
}

@media (max-width: 300px) or (max-height: 300px) {
	.app__header {
		display: none;
	}
}

.app__board {
	grid-row: 1 / 3;
	grid-column: 1;
	z-index: 1;
	width: 100%;
	height: 100%;
	min-height: 100vh;
}


@media (pointer: coarse) {
	.app__header {
		padding: 1em;
		padding-left: max(env(safe-area-inset-left, 0px), 1em);
		padding-right: max(env(safe-area-inset-right, 0px), 1em);
		padding-bottom: max(env(safe-area-inset-bottom, 0px), 1em);
	}
}
