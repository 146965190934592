
.color-placeholder {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: rgb(245, 245, 245);
	box-shadow: inset 0 0 0 1px rgb(229, 229, 229);
	width: 100%;
	height: 100%;
	aspect-ratio: 1;
}

.color-placeholder::before,
.color-placeholder::after {
	position: absolute;
	content: '';
	width: 100%;
	height: 100%;
	height: 0.5px;
	background-color: rgb(229, 229, 229);
}

.color-placeholder::before {
	transform: rotate(45deg) scale(1.39);
}

.color-placeholder::after {
	transform: rotate(-45deg) scale(1.39);
}

@media (prefers-color-scheme: dark) {
	.color-placeholder {
		background-color: rgb(9, 9, 9);
		box-shadow: inset 0 0 0 1px rgb(37, 37, 37);
	}

	.color-placeholder::before,
	.color-placeholder::after {
		background-color: rgb(37, 37, 37);
	}
}

@media (prefers-contrast) {
	.color-placeholder {
		border: 1px solid black;
	}

	.color-placeholder::before,
	.color-placeholder::after {
		background-color: black;
	}
}

@media (prefers-contrast) and (prefers-color-scheme: dark) {
	.color-placeholder {
		border: 1px solid black;
		background-color: white;
	}

	.color-placeholder::before,
	.color-placeholder::after {
		background-color: black;
	}
}
